<!--
 * @Description: 创建课程
 * @Author: xiawenlong
 * @Date: 2021-04-26 09:09:58
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-09-06 09:32:24
-->

<template>
  <div class="course-library-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">{{ courseId ? '编辑课程' : '创建课程' }}</span>
      </div>
      <el-form ref="form" :model="form" label-width="115px" :rules="rules">
        <el-form-item label="课程名称" prop="courseName">
          <el-input v-model="form.courseName" placeholder="请输入课程名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="课程特点" prop="introduction">
          <el-input v-model="form.introduction" placeholder="请输入课程特点" clearable></el-input>
        </el-form-item>
        <el-form-item label="授课形式" prop="courseType">
          <el-radio-group v-model="form.courseType" :disabled="Boolean(courseId)">
            <el-radio :label="0">录播课</el-radio>
            <el-radio :label="1">直播课</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="学分" prop="credit">
          <el-input
            v-model.number="form.credit"
            maxlength="3"
            placeholder="请输入学分"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="课时" prop="academicHour">
          <el-input
            v-model="form.academicHour"
            maxlength="3"
            placeholder="请输入课时"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="授课讲师">
          <el-select v-model="form.internalTeacherId" clearable placeholder="请选择授课讲师">
            <el-option
              v-for="item in internalTeacherList"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item ref="pic" label="课程封面图" prop="pic">
          <upload-image v-model="form.pic" :upload-img="$refs.pic"></upload-image>
          <p class="tip">图片大小最好在564 X 290，支持扩展名：.png .jpg .jpeg</p>
        </el-form-item>
        <el-form-item ref="attachments" label="课程课件">
          <!-- <el-upload drag action="" multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload> -->
          <upload-file v-model="form.attachments"> </upload-file>
        </el-form-item>
        <el-form-item label="课程详细描述">
          <tinymce ref="editor" v-model="form.summary" @onClick="onClick"></tinymce>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="submit">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { courseCreate, courseInfo, courseUpdate, internalTeacherList } from '@/api/course'
import to from 'await-to'
import UploadImage from '@/components/UploadImage'
import UploadFile from '@/components/UploadFile'
import Tinymce from '@/components/Tinymce'
export default {
  name: 'CourseLibraryAdd',
  components: { UploadImage, UploadFile, Tinymce },
  data() {
    return {
      rules: {
        courseName: [
          { required: true, message: '请输入课程名称', trigger: 'blur' },
          // { min: 4, max: 12, message: '请输入4~12个字', trigger: 'blur' },
        ],
        introduction: [
          { required: true, message: '请输入课程特点', trigger: 'blur' },
          // { min: 4, max: 12, message: '请输入4~12个字', trigger: 'blur' },
        ],
        courseType: [{ required: true, message: '请选择课程类型', trigger: 'change' }],
        pic: [{ required: true, message: '请上传课程封面图', trigger: 'change' }],
        credit: [
          { required: true, message: '请输入学分', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value) {
                const boolean = new RegExp('^[1-9][0-9]*$').test(value)
                if (!boolean) {
                  this.form.credit = ''
                } else {
                  value = parseInt(value)
                  if (value < 0 || value > 99) {
                    callback(new Error('请输入0~99的数字'))
                  }
                }
              }
              callback()
            },
          },
        ],
        academicHour: [
          { required: true, message: '请输入课时', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value) {
                const boolean = new RegExp('^[1-9][0-9]*$').test(value)
                if (!boolean) {
                  this.form.academicHour = ''
                } else {
                  value = parseInt(value)
                  if (value < 0 || value > 99) {
                    callback(new Error('请输入0~99的数字'))
                  }
                }
              }
              callback()
            },
          },
        ],
      },
      form: {
        courseType: 0,
      },
      internalTeacherList: [],
      loading: false,
      courseId: '',
    }
  },
  mounted() {
    const { courseId } = this.$route.params
    this.courseId = courseId
    if (courseId) this.getCourseInfo()
    this.fetchInternalTeacherList()
  },
  methods: {
    async getCourseInfo() {
      const [res, err] = await to(courseInfo({ courseId: this.courseId }))
      if (err) return this.$message.warning(err.msg)
      this.form = res.data
    },
    async fetchInternalTeacherList() {
      const [res, err] = await to(internalTeacherList({}))
      if (err) return this.$message.warning(err.msg)
      this.internalTeacherList = res.data
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.courseId ? this.update() : this.create()
        }
      })
    },
    async create() {
      this.loading = true
      const [, err] = await to(courseCreate(this.form))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('创建成功')
      this.$router.push('/course/library')
    },
    async update() {
      this.loading = true
      const [, err] = await to(courseUpdate({ ...this.form, courseId: this.courseId }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('修改成功')
      this.$router.push('/course/library')
    },
    onClick() {},
  },
}
</script>
<style lang="scss" scoped>
.course-library-add {
  .el-radio-button {
    margin-bottom: 20px;
  }
  .el-form-item .el-form-item {
    margin-bottom: 20px;
  }
  ::v-deep.uploader {
    height: 92px;
    .el-upload {
      width: 180px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 180px;
      height: 100%;
    }
  }
  ::v-deep.el-upload-dragger {
    background: #f5f5f5;
  }
}
</style>
